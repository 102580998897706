import React from 'react'
import v from "../assets/v.png"
import { Link } from 'react-router-dom'

const SuccessScreen = () => {
  return (
    <div className='w-full h-[80vh] flex flex-col items-center justify-center font-heebo animate-rubberBan'>
        
            <div className='h-12 w-12 flex justify-center items-center rounded-full bg-PraimeryBlue border-[3px] border-LightOrange'><img src={v} alt="" /></div>
            <p className='text-lg'> השאלון נשלח בהצלחה</p>
            <p className='font-bold text-lg' >תודה רבה</p>
        
        <Link to="/EnteryScreen" className='mt-4 rounded-lg bg-PraimeryBlue text-white font-bold text-lg py-3 px-5'>חזרה למסך כניסה</Link>

    </div>
  )
}

export default SuccessScreen