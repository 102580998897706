import React from 'react'

const Header = () => {
    return (
        <header className='bg-PraimeryBlue w-full p-4'>
            <p className='text-LightOrange font-light text-sm'>מחקר כוויות ילדים
            </p>
            <p className='text-LightOrange text-lg font-extrabold'>

                BGU</p>
        </header>
    )
}

export default Header