import React, { useState,useRef} from 'react'
import axios from 'axios';
const URL1='https://ai-sw-backapi.ismbonline.org/api/dashboard/convert_image'
// const URL1='http://ec2-3-66-56-221.eu-central-1.compute.amazonaws.com:8080/upload_image'
const Photo = ( {add,id,remove}) => {

  const [loading, setLoading] = useState(false);

const imgcomplet= async(file1)=>{

  // const reader = new FileReader();
  // reader.readAsDataURL(file1);
  // console.log(reader)






  
  const formData1 = new FormData();
  
  // formData1.append('image_data', file1);
  formData1.append('img_burns', file1);
  const response = await fetch('https://ai-sw-backapi.ismbonline.org/api/dashboard/convert_image', {
  // const response = await fetch('http://ec2-3-66-56-221.eu-central-1.compute.amazonaws.com:8080/upload_image', {
    method: 'POST',
    body: formData1,
  //   headers: {
  //     'Content-Type': 'multipart/form-data'
  // }
})
    // const response =await axios.post(URL1, formData1,
    //   {
    //       headers: { 'Content-Type': 'multipart/form-data' }
    //   })
if (response.ok) {
  console.log(response)
    const imageBlob = await response.blob();
    console.log("sadasd1")
  
    const imageUrl = URL.createObjectURL(imageBlob);
    console.log("sadasd2")
  
  
  
    setNameImage(imageUrl)}
    add(id)
  }
  
  
  
  // const response =await axios.post(URL1, formData1,
  //     {
  //         headers: { 'Content-Type': 'multipart/form-data' }
  //     })
  //   if (response?.status == 200) {
  //     console.log("enter")
     
  //     const imageBlob = new Blob([response.data],{ type: 'image/jpeg' })
      
  //     console.log(imageBlob)
  //     const imageUrl=URL.createObjectURL(imageBlob)
  //     setNameImage(imageUrl);
  //   }
// }

const fileInputRef = useRef(null);
 const [nameImage,setNameImage]=useState(null)
  const handleFileInputChange = async (e) => {
    setLoading(true)
    const file1 = e.target?.files[0];
    console.log(file1)
    await imgcomplet(file1)
    // const imgcomplet= async(file1)=>{}
    // console.log(file)
    // Do something with the selected file, such as displaying it or uploading it to a server
    // console.log(URL.createObjectURL(file1))
    // const img = new Image();
    // img.src = URL.createObjectURL(file1);
    // img.onload = () => {
      
    //   console.log(`width: ${img.width}, height: ${img.height} `)
    // };
    // setNameImage(URL.createObjectURL(file1));
    // add(URL.createObjectURL(file))
    setLoading(false)
  };

  const handleTakePhotoClick = () => {
    // Trigger the file input element to open the camera
    fileInputRef.current.click();
  };

  return (
    <div className='w-full '>
      <input
        type="file"
        accept="image/*"
        capture="environment" // This is used to specify that the camera should be used (not necessary on iOS)
        style={{ display: 'none' }} // Hide the file input element
        ref={fileInputRef}
        onChange={handleFileInputChange}
      />
      {nameImage?
      <div className='relative w-full  h-full'> <img className='w-full  h-full rounded-lg object-contain bg-Black' src={nameImage} alt="Converted"/>
        <button className='absolute rounded-full px-3 py-1 -top-[15px] text-Black -right-3 z-10 bg-white  drop-shadow-[1px_1px_10px_rgba(0,0,0,0.15)] ' onClick={()=>{
          remove(id)
          setNameImage(null)
          fileInputRef.current.value = '';  // זה מבטל את הבחירה בקובץ
        }} > X</button>
       </div>:
      <button className='w-full h-[140px] bg-Grey50 text-Grey500 border-2 border-Grey500 border-dashed flex justify-center items-center' disabled={loading}
        onClick={handleTakePhotoClick}
        style={{
          
          padding: '10px 20px',
          borderRadius: '8px',
          cursor: 'pointer',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
        }}
      >
       {loading?<div class="border-gray-300 h-12 w-12 animate-spin rounded-full border-8 border-t-PraimeryBlue" />: "+"}

      </button>}
      
    </div>
  );
};


export default Photo