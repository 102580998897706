import  React ,{useEffect, useRef, } from 'react'
import {createPortal} from 'react-dom'
const Modal = ({children,showModal,setShowModal}) => {
    // const[open,setOpen]=useState(true)
    const dialog=useRef(null)
    useEffect(()=>{
        if(showModal)
        {
          dialog.current.removeAttribute('open')
            dialog.current?.showModal()
        }
        else
        {
            dialog.current?.close()
        }
    }
    ,[showModal])

    //[-2px 5px 5px #FDFFFC]
  return (createPortal(
    <dialog  ref={dialog} className='w-[screen]  z-10 backdrop:bg-[#FDFFFC]/50  p-3 flex flex-col relative justify-center items-end  border border-1 border-[#011627] rounded-2xl shadow-md animate-opendown '>
        
    
      <button className='bg-LightBlue p-1 rounded-full px-3 hover:bg-SecondaryOrange absolute top-1 right-1' onClick={()=>setShowModal(false)} >X</button>
   
    
        { children}
    {/* </dialog>,document.body) */}
     </dialog>,document.body)
  )
}




export default Modal