import React from 'react'
import Header from '../components/Header'
import ProgressBar from '../components/ProgressBar'

const Questionnaire = () => {
  return (
    <div className='w-full min-h-screen flex flex-col justify-start '>
        <Header></Header>
        <ProgressBar></ProgressBar>

    </div>
  )
}

export default Questionnaire