import React, { useEffect, useState } from 'react'

const Step1 = ({ formData, setFormData }) => {
    // const [currentStep, setCurrentStep] = useState(0);
    // const [age, setAge] = useState(0);
    const [errorAge, setErrorAge] = useState(false)
    const steps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    const handleCheckAge = (value) => {
        if (0 >= value || 99 < value) {
            // alert("טעות")
            setErrorAge(true)
            setFormData({...formData, q1: "" })
        }

        else
         {         // alert("מצוין")
            // setAge(value);
            setErrorAge(false)
            setFormData({...formData, q1: value })
         }
        
    };

    useEffect(()=>{
        console.log(formData)
    },[formData])
    const handleSelect = (e) => {
        // setCurrentStep(e);
        setFormData({ ...formData, q2: e })
    };
    return (
        <div style={{ direction: "rtl" }} className='w-full grid gap-5 text-lg text-right font-heebo'>
            <ol className='list-decimal w-full  grid gap-5 pr-5'>
                <li >
                    <div className='w-full flex flex-row items-center  '>
                        <p className='text-nowrap text-lg p-2'>באיזה גיל הילד?</p>
                        <input type='number' className={`text-center w-[6rem] text-[16px] p-2 rounded-lg bg-LightBlue mr-3 text-Black  border-2 ${errorAge?"border-Negative":"border-white"}   focus:border-MidBlue outline-none`} placeholder='גיל הילד' onBlur={(e) => { handleCheckAge(e.currentTarget.value) }} />
                    </div>
                    {errorAge && <p className='text-Negative text-right font-bold text-[12px]'>הגיל צריך להיות ב1 ל100</p>}

                </li>
                <li>
                    <div className='w-full grid gap-4 '>
                        <p>כמה פעמים, מאלו הזכורות לך, ילדך נכווה?</p>
                    </div>
                </li>


            </ol>
            <div className='w-full grid grid-cols-10  gap-1'>
                {steps.map((step) => (
                    // <button onClick={()=>handleSelect(step)} className={`w-10 h-10 m-1 flex justify-center items-center ${currentStep==step?"bg-MidBlue text-white":"bg-white text-PraimeryBlue"} border border-PraimeryBlue rounded-full`}> {step}</button>
                    // <button onClick={() => handleSelect(step)} className={`grid items-center  ${currentStep == step ? "bg-MidBlue text-white" : "bg-white text-PraimeryBlue"} border border-PraimeryBlue rounded-full`}> {step}</button>
                    <button onClick={() => handleSelect(step)} className={`grid items-center text-[1rem] ${formData.q2 == step ? "bg-MidBlue text-white" : "bg-white text-PraimeryBlue"} border border-PraimeryBlue rounded-full`}> {step}</button>
                ))}

            </div>
            {/* { <button className='w-full px-5 py-3 bg-PraimeryBlue rounded-lg mt-3 text-white text-lg font-bold' >{currentStep == steps.length - 1 ? "ש-גר" : "הבא"}</button>} */}

        </div>
    )
}

export default Step1