import React from 'react'

const Step4 = ({formData,setFormData}) => {
  return (
    <div className='w-full flex flex-col ' style={{direction:"rtl"}}>
        <p>6.אנא תאר בקצרה ביצד נכווה הילד בכוויה בגללה הגעתם לבית החולים</p>
        <textarea type='text' placeholder='טקסט חופשי' className='w-full outline-none text-Black min-h-64  bg-LightBlue focus:ring-0 focus:border-2 p-2 focus:border-MidBlue' onChange={(e)=>setFormData({...formData,q6:e.currentTarget.value})}/>    </div>
  )
}

export default Step4