import React from 'react'
import Header from '../components/Header'
import openeyes from "../assets/openeyes.png"
import closeeyes from "../assets/closeeyes.png"
import { useState } from 'react'
import{useNavigate } from 'react-router-dom'

const Signin = () => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [user, setUser] = useState('')
    const [pwd, setPwd] = useState('')
    const [errorUser, setErrorUser] = useState('')
    const [errorlogin, setErrorLogin] = useState('')
    const navigate = useNavigate();

    function togglePasswordVisibility() {
        setIsPasswordVisible((prevState) => !prevState);
    }

    const handleOnBlur=()=>{
        setErrorLogin("")
    }

    const insertUserName = (value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (value == "") {
            setErrorUser("שדה זה הוא שדה חובה*")
            setUser("")
            
        }
        else if (!emailRegex.test(value)) {
            setErrorUser("יש למלא איימל נכון*")
            setUser(value)
            
        }
        else {
            setErrorUser("")
            setUser(value)
        }
    }


    const auth_submit = async(e) => 
    {
        e.preventDefault();
        if(user.toLowerCase()=='matan@g.com'&& pwd=='123')  
        {navigate('/EnteryScreen')}
        else
        {
            setErrorLogin("השם משתמש או הסיסמא אינם נכונים")
        }
    }
    return (
        <div className='bg-white w-full h-screen text-Black'>
            <Header />
            <div className='w-full h-[90%] p-4'>


                <form className='w-full flex flex-col justify-around h-full' onSubmit={auth_submit}>
                    <p className='w-full text-Black text-[18px] font-bold text-center'>
                        התחברות
                    </p>
                    <div className='w-full flex flex-col'>
                        <label className='font-bold text-[12px] text-right'>שם משתמש</label>
                        <input type="text" required className='bg-LightBlue rounded-lg px-4 py-2 border outline-0 border-LightBlue  focus:border-MidBlue   ' placeholder='e-mail' value={user} onBlur={handleOnBlur} onChange={(e) => insertUserName(e.currentTarget.value)} />
                        {errorUser&&<p className='text-Negative text-right font-bold text-[12px]'>{errorUser}</p>}
                        <label className='font-bold text-[12px] text-right'>סיסמא</label>
                        <div className=' w-full relative'>
                            <input type={isPasswordVisible ? "text" : "password"} required autoComplete="off" className='bg-LightBlue border flex outline-0 border-LightBlue  focus:border-MidBlue w-full rounded-lg px-4 py-2' onBlur={handleOnBlur}  placeholder='סיסמא'
                                onChange={(e) => setPwd(e.currentTarget.value)} />
                            {/* <p className='text-Negative text-right font-bold text-[12px]'>שדה זה הוא שדה חובה*</p> */}

                            <button type='button' className='absolute inset-y-0  right-0   px-2 text-gray-600'
                                onClick={togglePasswordVisibility}>
                                <img src={isPasswordVisible ?
                                    openeyes : closeeyes} />

                            </button>
                        </div>
                    {errorlogin&&<p className='text-Negative text-right font-bold text-[12px]'>{errorlogin}</p>}

                    </div>
                    <a rel="stylesheet" href="#" className='font-medium text-[12px] underline' > שחזור סיסמא</a>
                    {user && pwd&&!errorUser ? <button type='submit' className='bg-PraimeryBlue text-white font-bold text-lg rounded-lg p-4'>הבא</button> :
                        <button className='bg-Grey100 text-Grey300 font-bold text-lg rounded-lg p-4  ' type='submit' >הבא</button>}


                </form>

            </div>
        </div>
    )
}

export default Signin