import React from 'react'
import Header from '../components/Header'
import Signature from '../components/Signature'
import { useState } from 'react'
import { Link } from 'react-router-dom'


const ConsentformHE = () => {
    const [user, setUser] = useState('')
    const [signature1, setSignature1] = useState(false)
    const [signature2, setSignature2] = useState(false)
    const [errorUser, setErrorUser] = useState('')
    const currentDate = new Date();

    const insertUserName = (value) => {
        if (value == "") {
            setErrorUser("*שדה זה הוא שדה חובה")
            setUser("")
        }
        else if ((value.trim().split(/\s+/)).length == 1) {
            setErrorUser("*יש למלא שם פרטי ושם משפחה")
            setUser("")
        }
        else {
            setErrorUser("")
            setUser(value)
        }

    }


    return (
        <div className=' w-full h-screen text-Black font-[heebo] overflow-y-scroll bg-white'>
            <div className='relative w-full'>
                <Header></Header>
                <select defaultValue="עברית" name="" id="" className='absolute left-3 top-5 p-2 bg-LightBlue rounded-lg ' style={{ direction: "rtl" }} >
                    <option >עברית</option>
                    <option>English</option>
                    <option>عربيه</option>
                </select>
            </div>
            <p className='font-bold text-[18px] mt-5' style={{ lineHeight: "28px" }}>טופס הסכמה</p>
            <div className='text-right p-8 text-[12px] pt-0 space-y-2' style={{ direction: "rtl" }}>

                <p className='font-bold text-[12px]' style={{ lineHeight: "16px" }}>הורה יקר.ה,</p>
                <div className='font-normal  space-y-2 ' style={{ lineHeight: "16px" }}>
                    <p>מטרת המחקר הנוכחי היא להעמיק את הידע בנוגע לכוויות של ילדים מגיל שלושה חודשים עד 18 שנים. </p>
                    <p>השתתפותך מוערכת וחשובה לנו מאד. </p>
                    <p>המחקר הוא מחקר משותף של אוניברסיטת בן גוריון בנגב ובשיתוף עם משרד הבריאות, אגף הדיגיטל ומשרד המדע והטכנולוגיה. ההשתתפות כוללת מענה לשבע שאלות בלבד, צילום של הכוויה היא התנדבותית לחלוטין ועורכת כחמש דקות בלבד. </p>
                    <p>ניתן לדלג על כל שאלה שתרצה. </p>
                    <p> איננו מבקשים שום מידע אישי, כגון שמך או כתובתך. </p>

                    <p>בפרסומים מדעיים הקשורים למחקר לא יופיעו כל פרט מזהה שלך או של ילדך. </p>
                    <p>ההשתתפות במחקר לא מעמידה אותך ואת ילדך בכל סיכון שהוא או מספקת הטבה כשלהי. </p>
                    <p>אם יש לך שאלות כלשהן בנוגע למחקר זה אנא צורו איתנו קשר באמצעות הפרטים המופיעים מטה. </p>
                    <p>השאלון מנוסח בלשון זכר מטעמי נוחות בלבד אך מיועד לכל מגדר. </p>
                    <p className='font-bold text-[12px]' style={{ lineHeight: "16px" }}> אנו מאחלים לילדך רפואה שלמה! תודה על הזמן שאת.ה מקדיש.ה להשתתפות במחקר זה.</p>
                 
                </div>
                <p className='font-bold ' style={{ lineHeight: "16px" }}>בברכה, </p>
                <p className='font-bold ' style={{ lineHeight: "16px" }}>צוות המחקר </p>
                <p className='font-normal ' style={{ lineHeight: "16px" }}>ליצירת קשר אנא כתבו ל <text type='mail'>taliamst@bgu.ac.il </text> </p>
                <p className='font-normal ' style={{ lineHeight: "16px" }}> או בטלפון 0545655164 </p>
                <div className='w-full mb-1'>
                    <label className='font-bold text-[12px] text-right w-full'>שם ההורה</label>
                    <input type="text" required className='bg-LightBlue rounded-lg px-4 py-2 border outline-0 border-LightBlue w-full focus:border-MidBlue   ' placeholder='שם מלא' onChange={(e) => insertUserName(e.currentTarget.value)} />
                    {errorUser && <p className='text-Negative text-right font-bold text-[12px]'>{errorUser}</p>}
                </div>
                <p className='font-bold text-[12px] text-right w-full'>חתימה</p>
                <Signature setSignature={setSignature1}></Signature>
                <p className='text-left font-bold text-[12px]' style={{ lineHeight: "16px" }}>תאריך: {currentDate.toLocaleDateString('en-GB')}</p>

                <p className='font-bold text-[12px]' style={{ lineHeight: "16px" }}>רופא.ה</p>
                <p className='font-normal ' style={{ lineHeight: "16px" }}>בחתימתי אני מצהיר.ה כי הסברתי להורה הקטין בעל פה על המחקר בהתאם לכתוב בטופס זה. אני סבור.ה שההורה הבין את הנאמר, היה לו מספיק זמן לקרוא את הטופס וההורה הביע את הסכמתו להשתתף במחקר.</p>
                <p className='font-normal ' style={{ lineHeight: "16px" }}>בנוסף, אני מצהיר.ה כי הסברתי לקטין בנוכחות הוריו על המחקר והוא הביע את רצונו להשתתף בסקר.</p>
                <p className='font-bold text-[12px]' style={{ lineHeight: "16px" }}>שם רופא.ה:<span className='font-normal text-Grey300 mr-1 '>asd</span></p>
                <p className='font-bold text-[12px]' style={{ lineHeight: "16px" }}>מספר רישיון:<span className='font-normal text-Grey300 mr-1 '>1616</span></p>
                <p className='font-bold text-[12px]' style={{ lineHeight: "16px" }}>שם בית החולים:<span className='font-normal text-Grey300 mr-1'>בית החולים סורוקה</span></p>

                <Signature setSignature={setSignature2}></Signature>
                <p className='text-left font-bold text-[12px]' style={{ lineHeight: "16px" }}>תאריך: {currentDate.toLocaleDateString("en-GB")}</p>
                {/* <input type='file' capture="environment"></input> */}
                {user && signature1 && signature2 ? <button type='button' className='bg-PraimeryBlue text-white font-bold text-lg w-full rounded-lg p-4'><Link className='w-full' to="/Gallery">אישור</Link></button> :
                    <button className='bg-Grey100 text-Grey300 font-bold text-lg rounded-lg p-4 w-full  ' type='button'>אישור</button>}

            </div>

        </div>
    )
}

export default ConsentformHE