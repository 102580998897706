import React from 'react'
import Header from '../components/Header'
import { Link } from 'react-router-dom'

const EnteryScreen = () => {
  return (
    <div className='w-full h-screen' style={{direction:"rtl"}}>
        <Header></Header>
        <div className='w-full h-full p-5 flex flex-col justify-center items-center font-heebo -translate-y-20 '>
            <p className='text-SecondaryOrange text-2xl '>מחקר כוויות ילדים <span className='font-bold'>BGU</span></p>
            <p className='font-bold text-lg '>שלום דר’ ישראל ישראלי</p>
            <Link to="/ConsentformHE" className='bg-PraimeryBlue text-white px-5 py-4 mt-20 rounded-lg text-lg w-[60%] font-bold '> התחלה</Link> 
        </div>


    </div>
  )
}

export default EnteryScreen