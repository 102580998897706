import React, { useEffect, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import Modal from './Modal';

const Signature = ({setSignature}) => {
    const [showModal, setShowModal] = useState(false);
    const [dataSign, setdataSign] = useState(false);
    const [imageURL, setImageURL] = useState(null);
    const sign = useRef(null)
   
    function clearSign() {
        sign.current.clear()
        setdataSign(false)
        // console.log(sign)
    }
    function save() {
        setImageURL(sign.current.getTrimmedCanvas().toDataURL("image/png"));
        // console.log(sign.current.isEmpty())
        setSignature(true)
        setShowModal(false)
    }

    // useEffect(()=>{
    //     const canvasIsEmpty =sign?.current?.isEmpty()
    //     setdataSign(canvasIsEmpty )

    //     console.log(sign?.current)}
    // ,[sign?.current])

    // useEffect(() => {
    //     const canvasIsEmpty = !sign.current?.isEmpty();
    //     setdataSign(canvasIsEmpty);
    // }, [sign?.current]);


    const setSignatureOnChange = () => {
        const dataURL = sign.current.toDataURL()
        setdataSign(dataURL)
      }
    return (
        <div className='w-full'>
            <label>

            </label>
            {/* <input type="image" className='w-full h-[150px] bg-LightBlue border-[0.5px] border-dashed border-midnight rounded-md text-center' placeholder='חתימה כאן' onClick={()=>{setShowModal(true)}} value={imageURL?<img className='w-full h-9' src={imageURL}/>:null}/>  */}
            <div className='w-full h-[200px] bg-LightBlue border-[0.5px] border-dashed border-midnight rounded-md flex justify-center items-center' onClick={() =>  {setShowModal(true)
             setdataSign(false)}} >
                {imageURL ? <img className='max-h-[200px] ' src={imageURL} /> : <p className='w-full text-center font-bold '>לחתימה יש ללוחץ כאן</p>}
            </div>
            {showModal && <Modal showModal={showModal} setShowModal={setShowModal} >
                <div className='w-full text-center text-sm font-heebo  '>
                    <p className='font-bold'>חתימה</p>
                    <p>יש לחתום בתוך המשבת המסומנת</p>
                </div>
                <SignatureCanvas canvasProps={{ className: 'w-full h-[200px] bg-LightBlue border my-2 border-MidBlue rounded-[4px]' }} ref={sign} onEnd={setSignatureOnChange}></SignatureCanvas>
                <div className='w-full flex justify-between font-heebo font-bold  text-lg'>
                    {dataSign?
                        <button type='button' className='px-8 w-fit rounded-md text-white bg-PraimeryBlue' onClick={save}>שמור</button> :
                        <button type='button' className='px-8 w-fit rounded-md bg-Grey100 text-Grey300 disabled'>שמור</button>
                    }
                    {dataSign?
                    <button type='button' className='p-4 w-fit rounded-md bg-LightBlue ' onClick={clearSign}>נקנה חתימה</button>
                    :<button type='button' className='p-4 w-fit rounded-md bg-Grey100 text-Grey300 disabled' >נקנה חתימה</button>
                    }
                </div>
            </Modal>}
        </div>
    )
}

export default Signature