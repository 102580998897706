import React, { useState } from 'react'
// import {TiTick}from "react-icons/ti"
import v from '../assets/v.png'
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import SuccessScreen from './SuccessScreen';
const ProgressBar = () => {
    const steps = [1, 2, 3, 4]

    const[formData,setFormData]=useState({
        q1:"",
        q2:"",
        q3:"",
        q4:"",
        q5:"",
        q6:""
    })
    const [currentStep, setCurrentStep] = useState(0);
    const totalSteps = 4; // Total number of steps in the form

    const handleNext = () => {
        console.log(formData)
        setCurrentStep(currentStep + 1);

    };

    // const handlePrevious = () => {
    //     setCurrentStep(currentStep - 1);
    // };

    // const calculateProgress = () => {
    //     return ((currentStep - 1) / totalSteps) * 100;
    // };

    return (
        <div className='w-full min-h-[90vh] px-7 py-4 flex flex-col justify-between '>
            <div className='w-full'>{currentStep != 4 && <div className='w-full mb-10 flex justify-between '>
                {steps.map((step, index) => (
                    <div key={index} className={` flex justify-center items-center relative w-full before:content-[''] ${currentStep > index - 1 ? "before:bg-PraimeryBlue" : "before:bg-Grey100"}  before:w-full before:h-[3px] before:absolute before:right-1/2 before:top-1/2  before:-translate-y-1/2  before:first:hidden`} >
                        <div className={`${index <= currentStep ? "bg-PraimeryBlue text-white" : "text-Grey300 bg-Grey100"}  w-10 h-10 flex justify-center items-center rounded-full relative z-10`}>{index < currentStep ? <img src={v} /> : step}</div>
                    </div>))}
            </div>}

            {/* <button onClick={handlePrevious}>back</button> */}
            {currentStep == 0 && <Step1 formData={formData} setFormData={setFormData}></Step1>}
            {currentStep == 1 && <Step2 formData={formData} setFormData={setFormData}></Step2>}
            {currentStep == 2 && <Step3 formData={formData} setFormData={setFormData}></Step3>}
            {currentStep == 3 && <Step4 formData={formData} setFormData={setFormData}></Step4>}
            {currentStep == 4 && <SuccessScreen></SuccessScreen>}
            </div>
            {currentStep != 4 &&  <button disabled={(currentStep==0&&(formData.q1==""||formData.q2==""))||(currentStep==1&&(formData.q3==""||formData.q4==""))||(currentStep==2&&(formData.q5==""))||(currentStep==3&&(formData.q6==""))} className='w-full px-5 py-3 bg-PraimeryBlue rounded-lg mt-3 text-white text-lg font-bold disabled:bg-Grey300' onClick={handleNext}>{currentStep == steps.length - 1 ? "סיים" : "המשך"}</button>}
        </div>
    );
}




// <div className="max-w-xl mx-auto mt-8 p-6 border rounded shadow-lg">
//         {/* Form Steps */}
//         <div className="mb-4">
//           <div className="relative h-3 rounded bg-Grey300">
//             <div
//               className="absolute top-0 left-0 h-3 rounded bg-MidBlue"
//               style={{ width: `${calculateProgress()}%` }}
//             ></div>
//           </div>
//           <div className="flex justify-between mt-2">
//             <span>Step {currentStep} of {totalSteps}</span>
//             <div>
//               {currentStep > 1 && (
//                 <button onClick={handlePrevious} className="btn btn-secondary mr-4">Previous</button>
//               )}
//               {currentStep < totalSteps ? (
//                 <button onClick={handleNext} className="btn btn-primary">Next</button>
//               ) : (
//                 <button className="btn btn-success">Submit</button>
//               )}
//             </div>
//           </div>
//         </div>

//         {/* Form Content */}
//         {currentStep === 1 && (
//           <div>
//             <h2 className="text-xl font-bold mb-4">Step 1: Personal Information</h2>
//             {/* Your form fields for step 1 */}
//           </div>
//         )}
//         {currentStep === 2 && (
//           <div>
//             <h2 className="text-xl font-bold mb-4">Step 2: Address Information</h2>
//             {/* Your form fields for step 2 */}
//           </div>
//         )}
//         {currentStep === 3 && (
//           <div>
//             <h2 className="text-xl font-bold mb-4">Step 3: Confirmation</h2>
//             {/* Your form fields for step 3 */}
//           </div>
//         )}
//       </div>

export default ProgressBar



