import React ,{useEffect}from 'react'
import logo from '../assets/logoOnboarding.png'
import{useNavigate } from 'react-router-dom'
const Onboarding = () => {
    const navigate = useNavigate();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
     
      navigate('/Signin');
    
    }, 3000);
    return () => {
        // Clean up the timeout if the component unmounts before the timeout is reached
        clearTimeout(timeoutId);
      };
    }, []);

    return (
        <div className='w-full min-h-screen bg-PraimeryBlue flex flex-col justify-center items-center  '>
            <div className='mb-[186px]'>
                <p className='text-LightOrange text-lg'>מחקר כוויות ילדים
                </p>
                <p className='text-LightOrange text-lg font-bold'>

                    BGU</p>
                
            </div>
            <img className='w-[150px] animate-disaper' src={logo}/>

        </div>
    )
}

export default Onboarding