import React, { useState } from 'react'

const Step3 = ({formData,setFormData}) => {
    const [value, setValue] = useState("")
    const q5=["רכב פרטי בבעלותנו","אמבולנס","תחבורה ציבורית","אחר"]
    return (
        <div className='w-full flex flex-col items-start p-4 ' style={{direction:"rtl"}} >
            <p className='text-right'>5.באיזה אופן הגעתם לבית החולים לקבל טיפול?</p>
            {q5.map((q,index)=>(
                // <div><input type='radio' name="selectq5" value={q} id={index} checked={value == q} onChange={(e) => { setValue(e.target.value) }} /> {q=="אחר"&&value == "אחר" ?<input type='text' className='text-Black bg-LightBlue rounded-lg border-2 p-2 border-MidBlue outline-none' placeholder='טקסט חופשי'/>:<label htmlFor={index}>{q}</label>}</div>
                <div><input type='radio' name="selectq5" value={q} id={index} checked={formData.q5 == q} onChange={(e) => { setFormData({...formData,q5:e.target.value}) }} /> {q=="אחר"&&formData.q5 == "אחר" ?<input type='text' className='text-Black bg-LightBlue rounded-lg border-2 p-2 border-MidBlue outline-none' placeholder='טקסט חופשי'/>:<label htmlFor={index}>{q}</label>}</div>
            ))}
{/*             
            <div><input type='radio' name="selectq5" value={"לא"} id={"לא"} checked={value == "לא"} onChange={(e) => { setValue(e.target.value) }} /> <label htmlFor="לא">לא</label></div>
            <div><input type='radio' name="selectq5" value={"לא ידוע לי"} id={"לא ידוע לי"} checked={value == "לא ידוע לי"} onChange={(e) => { setValue(e.target.value) }} /> <label htmlFor="לא ידוע לי">לא ידוע לי</label></div>
            <div><input type='radio' name="selectq5" value={"לא ידוע לי"} id={"לא ידוע לי"} checked={value == "לא ידוע לי"} onChange={(e) => { setValue(e.target.value) }} /> <label htmlFor="לא ידוע לי">לא ידוע לי</label></div>
         */}
        
        </div>
    )
}

export default Step3