import React from 'react'
import Photo from '../components/Photo'
import Header from '../components/Header'
import { Link } from 'react-router-dom'
import { useState } from 'react'

const Gallery = () => {
  
 
  
  const [photos,setPhoto]=useState([])
  console.log(photos)
  const add=(id)=>{
    setPhoto((prevPhotos)=> ({...prevPhotos,[id]:id}))
  }
  const remove=(id)=>{
    // setPhoto(...photos.filter((item)=>(item!=id)))
    setPhoto((prevPhotos)=> ({...prevPhotos,[id]:""}))
  }
  return (
    <div className='w-full h-screen  '>
      <Header></Header>
      <div className='w-full h-[90%] p-7 flex flex-col justify-between items-center'>
      <div className='w-full my-auto'>  
      <div className=' w-full  grid grid-cols-3 gap-2  '>
        <Photo id="1" add={add} remove={remove}></Photo>
        <Photo id="2" add={add} remove={remove}></Photo>
        <Photo id="3" add={add} remove={remove}></Photo>
        <Photo id="4" add={add} remove={remove}></Photo>
        <Photo id="5" add={add} remove={remove}></Photo>
        <Photo id="6" add={add} remove={remove}></Photo>
        </div>
        <p className='text-sm mt-3 text-right' style={{direction:"rtl"}}>בלחיצה עך כפתור “ שמור והמשך לשאלון” אני מאשר.ת כי לא ניתן לזהות פנים בתמונות אותם צילמתי.</p>
        </div>
        <Link to="/Questionnaire" className='bg-PraimeryBlue text-white px-5 py-4 m-3 rounded-lg text-lg w-full font-bold '> שמירה ומעבר לשאלון</Link> 
        
        </div>

        
        </div>
  )
}

export default Gallery