import logo from './logo.svg';
import './App.css';
import { useState } from 'react';
import Onboarding from './pages/Onboarding';
import Signin from './pages/Signin';
import ConsentformHE from './pages/ConsentformHE';
import Gallery from './pages/Gallery';
import ProgressBar from './components/ProgressBar';
import EnteryScreen from './pages/EnteryScreen';
import Questionnaire from './pages/Questionnaire';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
function App() {
  //קוד בשביל הקרון:
  // var text ="Edit and save to reload. Edit and save to reload. Edit save to reload. Edit save to reload. Edit save to reload. Edit save to reload. Edit save to reload. Edit save to reload." 
  // const spamshow= text.length>100? true : false
  // const [show,setshow]=useState(false)

   {/* <div>

        
        <p className={`w-[250px]  ${spamshow&&show?"break-words line-clamp-2":""} `}>
          {text}
          
        </p>
        {spamshow&&<button className='bg-bubble-gum' onClick={()=>{setshow(!show)}}>{show?"המשך לקרוא":"סגור"}</button>}
        </div> */}
  

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Onboarding />,
      // errorElement: <ErrorPage/>,
      children: [
        // {
        //   path: "/Home",
        //   element: <Home/>,
        // },
        // {
        //   path: "dashboard",
        //   element: <Dashboard />,
        //   loader: ({ request }) =>
        //     fetch("/api/dashboard.json", {
        //       signal: request.signal,
        //     }),
        // },
        // {
        //   element: <AuthLayout />,
        //   children: [
        //     {
        //       path: "login",
        //       element: <Login />,
        //       loader: redirectIfUser,
        //     },
        //     {
        //       path: "logout",
        //       action: logoutUser,
        //     },
        //   ],
        // },
      ],
    },
    { 
      path: "Signin",
      element: <Signin />,
    },
    {
      path: "EnteryScreen",
      element: <EnteryScreen />,
    },
    {
      path: "Questionnaire",
      element: <Questionnaire />,
    },
    {
      path: "ProgressBar",
      element: <ProgressBar />
    },
    {
      path: "Gallery",
      element: <Gallery />
    },
    {
      path: "ConsentformHE",
      element: <ConsentformHE />
    },

  ]);






  return (
    <div className="App">
      <header className="App-header font-heebo">
      <RouterProvider router={router} />
 
      </header>
    </div>
  );
}

export default App;
{/* <a
          className="App-link bg-MidBlue"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}