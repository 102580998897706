import React, { useState } from 'react'

const Step2 = ({formData,setFormData}) => {
    const [value, setValue] = useState("")
    const [value1, setValue1] = useState("")
    const q4 = ["אמפטיגו", "מחלת Stevens-Johnson"," שלבקת חוגרת","תסמונת העור הצרוב של סטפילוקוק (SSSS)","bullous pemphigoid","אטופיק דרמטיטיס","אחר"]
    return (
        <div style={{ direction: "rtl"  }} className='text-[16px]'  >
            <ol className='list-decimal text-right pr-5 ' start="3">
                <li className=''>
                    <div className='w-full flex flex-col  items-start'>
                        <p className='p-1 text-lg'>האם ילדך סבל או סובל כעת ממחלות עור?</p>
                        <div><input type='radio' name="selectq3" value={"כן"} id={"כן"} checked={value == "כן"} onChange={(e) => { setValue(e.target.value) 
                            setFormData({...formData,q3:e.target.value})
                        }} /> <label htmlFor="כן">כן</label></div>
                        <div><input type='radio' name="selectq3" value={"לא"} id={"לא"} checked={value == "לא"} onChange={(e) => { setValue(e.target.value) 
                             setFormData({...formData,q3:e.target.value})
                        }} /> <label htmlFor="לא">לא</label></div>
                        <div><input type='radio' name="selectq3" value={"לא ידוע לי"} id={"לא ידוע לי"} checked={value == "לא ידוע לי"} onChange={(e) => { setValue(e.target.value)
                             setFormData({...formData,q3:e.target.value})
                         }} /> <label htmlFor="לא ידוע לי">לא ידוע לי</label></div>
                        <div className='w-full flex items-center '>
                            <p className={` ${value == "כן" ? "" : "text-Grey300"} `}>במידה וכן, באיזה גיל אובחן?</p>
                            <input type='number' placeholder='גיל אבחון' className='w-[6rem]  text-center rounded-lg p-2 text-Black bg-LightBlue border-2 border-white  focus:border-MidBlue outline-none' disabled={value != "כן"} minLength="3" /></div>
                    </div>
                </li>
                <li >

                    <div className='w-full flex flex-col  items-start'>
                        <p className='p-1 text-lg'>האם לילד אבחנות קודמות הקשורות למחלת עור?</p>

                        <div><input type='radio' name="selectq4" value={"כן"} id={"1כן"} checked={value1 == "כן"} onChange={(e) => { setValue1(e.target.value)
                             setFormData({...formData,q4:e.target.value})
                         }} /> <label htmlFor="1כן">כן</label></div>
                        <div><input type='radio' name="selectq4" value={"לא"} id={"1לא"} checked={value1 == "לא"} onChange={(e) => { setValue1(e.target.value)
                             setFormData({...formData,q4:e.target.value})
                         }} /> <label htmlFor="1לא">לא</label></div>
                        <div className='w-full flex flex-col  items-start gap-1'>
                            <p className={`${value1 == "כן" ? "" : "text-Grey300"}`}>סמן את האבחנה הרלוונטית</p>
                            {q4.map((choose,i)=>(<div className='w-full p-1 flex flex-row items-baseline'><input type='checkbox' className='text-Black ml-3' id={i} disabled={value1 != "כן"} />
                            <label className={`${value1 == "כן" ? "" : "text-Grey300"}`} htmlFor={i}>{choose}</label>
                            </div>))}
                            </div>
                    </div>

                </li>

            </ol>

        </div>
    )
}

export default Step2